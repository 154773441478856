import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { http } from '../../utils/Utils';
import toast from 'react-hot-toast';
import Button from '../UI/buttons/Button';
import { SelectField } from '../../components/Form/SelectField';
import { convertPrice } from '../../utils/Prices';
import Stepper from './Stepper';
import ModalConfirmDialog from './ModalConfirmDialog';

const PaymentBox = ({
  price,
  updatePayment,
  mode,
  invoiceGenerated = false,
  rentId,
  reservationId,
  invoicingMoment,
  invoice,
  refetchFunction,
  className = '',
}) => {
  const form = useForm();
  const [isInvoiceGenerated, setIsInvoiceGenerated] = useState(invoiceGenerated);
  const [confirmInvoice, setConfirmInvoice] = useState(false);
  const [invoiceId, setInvoiceId] = useState(invoice);
  const [disabledInvoiceButton, setDisabledInvoiceButton] = useState(false);
  const [priceValue, setPriceValue] = useState(price);

  useEffect(() => {
    if (invoiceGenerated) {
      updateInvoiceGenerated(invoiceGenerated);
    }
  }, [invoiceGenerated]);

  useEffect(() => {
    if (!invoicingMoment) {
      toast.error(`Manca momento fatturazione: ${reservationId}`);
    }
  }, [invoicingMoment, reservationId]);

  const updateInvoiceGenerated = (value) => {
    setIsInvoiceGenerated(value);
  };

  const generateInvoice = async () => {
    try {
      setDisabledInvoiceButton(true);
      if (invoicingMoment === 'deposit') {
        const response = await http({
          url: `/invoice/deposit`,
          method: 'POST',
          form: {
            rentId: rentId,
            invoicingMoment: invoicingMoment,
            invoicingType: mode,
            amount: priceValue,
          },
        });

        if (response?._id) {
          setInvoiceId(response._id);
          toast.success(`Fattura deposito generata`);
          setDisabledInvoiceButton(false);
          setPriceValue(0);
          refetchFunction();
        }
        return;
      } else if (rentId) {
        const response = await http({
          url: `/invoice/rent`,
          method: 'POST',
          form: {
            rentId: rentId,
            invoicingMoment: invoicingMoment,
            invoicingType: mode,
          },
        });
        if (response?._id) {
          setInvoiceId(response._id);
          toast.success(`Fattura ${mode === 'movolab' ? 'Movolab' : 'diretta'} generata`);
          setDisabledInvoiceButton(false);
          updateInvoiceGenerated(true);
          refetchFunction();
        } else {
          toast('Nessuna fattura da generare');
        }
      } else if (reservationId) {
        const response = await http({
          url: `/invoice/reservation`,
          method: 'POST',
          form: {
            reservationId: reservationId,
            invoicingMoment: invoicingMoment,
            invoicingType: mode,
          },
        });

        if (response?._id) {
          setInvoiceId(response._id);
          toast.success(`Fattura ${mode === 'movolab' ? 'Movolab' : 'diretta'} generata`);
          setDisabledInvoiceButton(false);
          refetchFunction();
        } else {
          toast('Nessuna fattura da generare');
        }
        updateInvoiceGenerated(true);
      }
    } catch (err) {
      console.error(err);
      setDisabledInvoiceButton(false);
      toast.error('Errore nella generazione della fattura');
    }
  };

  const payInvoice = (data) => {
    setDisabledInvoiceButton(true);
    updatePayment(data, mode, price, invoiceId);
    refetchFunction();
  };

  return (
    <>
      <div className="flex justify-center">
        <Stepper
          className="py-4"
          steps={[
            { content: '1', isCurrent: !isInvoiceGenerated },
            { content: '2', isCurrent: isInvoiceGenerated },
          ]}
        />
      </div>

      {!isInvoiceGenerated ? (
        <div className={`flex flex-col p-5 bg-slate-100 rounded-xl mx-[-0.75rem] ${className}`}>
          <div className="flex flex-row justify-between items-center text-lg font-semibold">
            <div>Totale</div>
            <div>{convertPrice(priceValue)}</div>
          </div>

          {priceValue > 0 && (
            <div className="flex justify-center pt-2 space-x-2">
              <Button
                btnStyle="blue"
                isLoading={disabledInvoiceButton}
                onClick={(e) => {
                  e.preventDefault();
                  setConfirmInvoice(true);
                }}
                className="px-10"
              >
                Crea Fattura
              </Button>
              {invoicingMoment === 'deposit' && (
                <Button
                  btnStyle="darkGray"
                  isLoading={disabledInvoiceButton}
                  onClick={(e) => {
                    e.preventDefault();
                    updateInvoiceGenerated(true);
                  }}
                  className="px-10"
                >
                  Vai a Incasso
                </Button>
              )}
            </div>
          )}
        </div>
      ) : (
        <form onSubmit={form.handleSubmit(payInvoice)}>
          <div className={`flex flex-col p-5 bg-slate-100 rounded-xl mx-[-0.75rem] ${className}`}>
            <div className="flex flex-row justify-between items-center text-lg font-semibold">
              <div>Totale</div>
              <div>{convertPrice(priceValue)}</div>
            </div>

            <div className="flex justify-center my-3">
              <SelectField
                name="paymentMethod"
                form={form}
                className="w-56"
                validation={{
                  required: {
                    value: true,
                    message: 'Inserisci Metodo',
                  },
                }}
                options={[
                  { value: 'banco', label: 'Banco' },
                  //{ value: 'carta di credito', label: 'Carta di Credito' },
                  //{ value: 'bonifico', label: 'Bonifico' },
                ]}
                placeholder="Metodo di pagamento"
              />
            </div>

            <div className="flex justify-center">
              <Button className="w-32" isLoading={disabledInvoiceButton}>
                Incassa
              </Button>
            </div>
          </div>
        </form>
      )}

      <ModalConfirmDialog
        headerChildren="Creazione fattura"
        title={`Confermi di voler fatturare?`}
        description={`Continuando, verrà subito emessa fattura per ${convertPrice(priceValue)}`}
        okText="Fattura"
        isVisible={confirmInvoice}
        handleOk={() => {
          generateInvoice(true);
          setConfirmInvoice(false);
        }}
        handleCancel={() => {
          setConfirmInvoice(false);
        }}
      />
    </>
  );
};

export default PaymentBox;
