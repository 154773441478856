import React, { useContext, useEffect, useState } from 'react';
import ProgressCircle from '../UI/ProgressCircle';
import { http } from '../../utils/Utils';
import toast from 'react-hot-toast';
import WhiteBox from '../UI/WhiteBox';
import Button from '../UI/buttons/Button';
import { UserContext } from '../../store/UserContext';
import { MOVOLAB_ROLE_ADMIN } from '../../utils/Utils';
import RevenueSharesMonthlyRecapTableItem from '../RevenueShares/RevenueSharesMonthlyRecapTableItem';
import RevenueShareMonthlyRecapDetails from '../RevenueShares/RevenueShareMonthlyRecapDetails';

const ClientUsage = ({ ...props }) => {
  const [monthlyStats, setMonthlyStats] = useState(null);
  const [monthlyRevenueShares, setMonthlyRevenueShares] = useState(null);
  const [showExpand, setShowExpand] = useState(false);

  const { data: currentClient } = useContext(UserContext);
  const isAdmin = currentClient?.role === MOVOLAB_ROLE_ADMIN;

  const clientId = isAdmin ? props.clientId : currentClient?.client?._id;

  const monthNames = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre',
  ];

  const date = new Date(props.month);
  const formattedMonth = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}`; // getMonth() is zero-based
  const currentMonthName = monthNames[date.getMonth()]; // getMonth() is zero-based
  const currentYear = date.getFullYear();

  const fetchMonthlyStats = async (month) => {
    try {
      if (isAdmin) {
        const response = await http({
          url: `/clients/stats/monthly?month=${month}&clientId=${clientId}`,
        });
        setMonthlyStats(response);
      } else {
        const response = await http({ url: `/clients/stats/monthly?month=${month}` });
        setMonthlyStats(response);
      }
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  const fetchMonthlyRecap = async (clientId, period) => {
    try {
      const response = await http({
        url: `/clientPayments/revenueShareMonthlyRecap/client/${clientId}/period/${period}`,
      });
      setMonthlyRevenueShares(response);
    } catch (err) {
      console.error(err);
      toast.error(err?.reason?.error || 'Errore');
    }
  };

  useEffect(
    () => {
      fetchMonthlyStats(formattedMonth);
      fetchMonthlyRecap(clientId, formattedMonth);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!monthlyStats) {
    return null;
  }

  return (
    <WhiteBox
      className="mx-0"
      innerClassName="px-6 py-5"
      isCollapsible="true"
      isExpanded={props.isExpanded}
      headerChildren={(expanded) => (
        <div className="flex gap-x-4 items-center font-bold">
          {'Utilizzo'} {currentMonthName} {currentYear}
        </div>
      )}
      {...props}
    >
      <div className="flex flex-wrap lg:flex-nowrap flex-initial w-full p-4 text-sm bg-slate-100 gap-2 rounded-lg">
        <div className="font-bold text-lg text-slate-800 text-center">Pack</div>

        <div className="flex flex-wrap lg:flex-nowrap gap-2 lg:gap-5 w-full max-w-[40rem] justify-between text-sm md:text-normal">
          <div className="w-[calc(50%-0.25rem)] md:w-1/5 min-w-20">
            <ProgressCircle usage={monthlyStats?.rentalLocationCount} total={6} />
            <div className="my-1 font-bold text-lg text-slate-800 text-center">Punti Nolo</div>
          </div>
          <div className="w-[calc(50%-0.25rem)] md:w-1/5 min-w-20">
            <ProgressCircle usage={monthlyStats?.vehicleCount} total={6} />
            <div className="my-1 font-bold text-lg text-slate-800 text-center">Veicoli</div>
          </div>
          <div className="w-[calc(50%-0.25rem)] md:w-1/5 min-w-20">
            <ProgressCircle usage={monthlyStats?.monthlyRents} total={100} />
            <div className="my-1 font-bold text-lg text-slate-800 text-center">Noleggi</div>
          </div>
          <div className="w-[calc(50%-0.25rem)] md:w-1/5 min-w-20">
            <ProgressCircle usage={monthlyStats?.monthlyComodati} total={100} />
            <div className="my-1 font-bold text-lg text-slate-800 text-center">Comodati</div>
          </div>
          <div className="w-[calc(50%-0.25rem)] md:w-1/5 min-w-20">
            <ProgressCircle usage={monthlyStats?.monthlyMNP} total={100} />
            <div className="my-1 font-bold text-lg text-slate-800 text-center">MNP</div>
          </div>
        </div>

        <div className="flex items-center flex-1 justify-end">
          <Button to={`/settings/abbonamento/${formattedMonth}`} btnStyle="tableItemAction">
            Dettagli &raquo;
          </Button>
        </div>
      </div>
      {monthlyRevenueShares && monthlyRevenueShares.revenueShares.length > 0 && (
        <div className="w-full p-4 text-sm bg-slate-100 gap-2 rounded-lg mt-4">
          <div className="font-bold text-lg text-slate-800">Corrispettivi mensili</div>

          <div className="bg-white border-t border-gray-200 overflow-hidden relative mt-2">
            <div className="overflow-x-auto h-full">
              {/* Table */}
              <div>
                <table className="w-full table-fixed">
                  {/* Table header */}
                  <thead className="text-xs font-semibold uppercase text-gray-500 bg-gray-50">
                    <tr>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">Cliente</div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">Mese</div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">Anno</div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">Pagato</div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">Stato</div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-center">Movo</div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Corrispettivo <br /> Totale
                        </div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Corrispettivo <br /> Cliente
                        </div>
                      </th>
                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Ultimo <br /> Aggiornamento
                        </div>
                      </th>

                      <th className="first:pl-5 last:pr-5 py-3 whitespace-nowrap"></th>
                    </tr>
                  </thead>
                  {/* Table body */}
                  <tbody className="text-sm divide-y divide-gray-200 border-t border-b border-gray-200">
                    <RevenueSharesMonthlyRecapTableItem
                      monthlyRecap={monthlyRevenueShares}
                      isClient={true}
                      showDetails={false}
                      expandFunction={() => {
                        setShowExpand(!showExpand);
                      }}
                    />
                  </tbody>
                </table>
                {showExpand && (
                  <RevenueShareMonthlyRecapDetails
                    monthlyRecap={monthlyRevenueShares}
                    showDetails={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </WhiteBox>
  );
};

export default ClientUsage;
